import React from 'react'

const Printer = props => (
  <svg
    aria-label="Printer"
    x="0px"
    y="0px"
    viewBox="0 0 248.059 248.059"
    width="3em"
    height="3em"
    {...props}
  >
    <g>
      <path
        d="M230.559,62.498h-27.785V17.133c0-4.143-3.358-7.5-7.5-7.5H52.785c-4.142,0-7.5,3.357-7.5,7.5v45.365H17.5
        c-9.649,0-17.5,7.85-17.5,17.5v96.225c0,9.649,7.851,17.5,17.5,17.5h27.785v37.203c0,4.143,3.358,7.5,7.5,7.5h142.488
        c4.142,0,7.5-3.357,7.5-7.5v-37.203h27.785c9.649,0,17.5-7.851,17.5-17.5V79.998C248.059,70.349,240.208,62.498,230.559,62.498z
        M60.285,24.633h127.488v37.865H60.285V24.633z M187.773,223.426H60.285v-74.404h127.488V223.426z M233.059,176.223
        c0,1.355-1.145,2.5-2.5,2.5h-27.785v-37.201c0-4.143-3.358-7.5-7.5-7.5H52.785c-4.142,0-7.5,3.357-7.5,7.5v37.201H17.5
        c-1.355,0-2.5-1.145-2.5-2.5V79.998c0-1.356,1.145-2.5,2.5-2.5h35.285h142.488h35.285c1.355,0,2.5,1.144,2.5,2.5V176.223z"
      />
      <circle cx="195.273" cy="105.76" r="10.668" />
      <path
        d="M158.151,163.822H89.907c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h68.244c4.142,0,7.5-3.357,7.5-7.5
        C165.651,167.18,162.294,163.822,158.151,163.822z"
      />
      <path
        d="M158.151,193.623H89.907c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h68.244c4.142,0,7.5-3.357,7.5-7.5
        C165.651,196.98,162.294,193.623,158.151,193.623z"
      />
    </g>
  </svg>
)

export default Printer
